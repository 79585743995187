body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.flex {
  display: flex;
  gap: 10px;
}

.cardex .ant-card-head {
  background-color: #efeded;
}

.custom_form {
  display: flex;
  flex-basis: 30%;
  flex-wrap: wrap;
}

.custom_form .ant-form-item {
  margin: 5px;
  border: 1px dashed #c9bebe;
  padding: 5px;
  cursor: pointer;
}

.custom_form .ant-form-item:hover {
  background: rgb(228, 215, 250, 80);
}

.custom_form .ant-form-item.active {
  background-color: rgb(65 193 65);
}

.component_plate_fetcher {
  background-color: whitesmoke;
  border: 1px dashed #eee;
  padding: 10px;
  align-items: center;
  justify-content: center;
  display: flex;
  min-height: 200px;
  border-radius: 4px;
}

.table_responsive {
  overflow-x: auto;
}

.custom_table {
  margin-bottom: 20px;
}

.custom_table td,
.custom_table th {
  padding: 5px;
}

.custom_table th {
  font-size: 12px;
}

.form_no_padding .ant-form-item {
  margin: 0px;
  padding: 0px;
}

.trigger {
  padding: 0 24px;
  font-size: 18px;
  line-height: 64px;
  cursor: pointer;
  transition: color 0.3s;
}

.trigger:hover {
  color: #1890ff;
}

.logo {
  height: 40px;
  margin: 16px;
  margin-bottom: 10px;
  text-align: center;
}

.logo img {
  height: 40px;
}

.site-layout .site-layout-background {
  background: #fff;
  flex-direction: row;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.avatar {
  width: 40px;
  height: 40px;
  background: #35d7d8;
  border-radius: 50%;
  padding: 8px;
  margin-right: 30px;
}