@import '~antd/dist/antd.less';

img {
  max-width: 100%;
}
.wrapper {
      height: 100vh;
      background: #eff2f5;
      
}

.site-layout-content {
    min-height: calc(100vh - 160px);
  overflow: hidden;
    background: #fff;
    padding: 10px 20px;
    margin: 20px 0px;
    border-radius: 5px;
     box-shadow: 0 0 30px #e3e3e3;
}

.ant-page-header{
  padding: 10px ;
}

.auth-box {
       margin: auto;
       margin-left: 20px;
       margin-right: 20px;
       padding: 24px;
       background: #fff;
       max-width: 500px;
       border-radius: 8px;
       box-shadow: 0 0 30px #e3e3e3;
       position: absolute;
       top: 0px;
       left: 0px;
       right: 0px;
       bottom: 0px;
       height: 430px;
  }

  .loginav {
      display: flex;
        align-items: center;
        gap: 10px;
        padding: 0px 10px;
        border: 2px solid #76a3d5;
        border-radius: 6px;
        height: 40px;
        background:#77a4d524;
        margin-right: 30px;
        justify-content: right;
        cursor: pointer;
  }
  .loginav .avatar {
        height: 30px;
        width: 30px;
      margin-right: 0px;
  }

    
@media screen and (min-width: 768px) {
  .auth-box {
  height: 350px;
  margin: auto !important;
  }
}

#components-layout-demo-top .logo {
  float: left;
  width: 120px;
  height: 31px;
  margin: 16px 24px 16px 0;
  background: rgba(255, 255, 255, 0.3);
}

.ant-row-rtl #components-layout-demo-top .logo {
  float: right;
  margin: 16px 0 16px 24px;
}


.right-menu{
  position: absolute !important;
  top: 0; 
  right: 40px !important;

   max-width: 120px !important;
   overflow: hidden;
   white-space: nowrap;
   overflow: hidden;
   text-overflow: ellipsis;
  
}




svg {
  vertical-align: baseline;
}
@primary-color: #1DA57A;